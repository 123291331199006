import {createStore} from 'vuex'

export default createStore({
    state: {
        isLoading: false,
        userData: undefined,
        sideBarMenuVisible: false,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setUserData(state, userData) {
            state.userData = userData;
        },
        setSidebarMenuVisible(state, sideBarMenuVisible) {
            state.sideBarMenuVisible = sideBarMenuVisible
        },
    },
    actions: {},
    modules: {}
})
