<template>
  <div class="d-flex flex-column align-items-center vue-container col-12">
    <Transition name="slide-burger-menu">
      <div v-if="sideBarMenuVisible" class="menu-container col-12">
        <SidebarMenu/>
      </div>
    </Transition>
    <LoadingOverlay v-if="this.isLoading"/>
    <router-view/>
  </div>
</template>


<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import SidebarMenu from "@/views/components/SidebarMenu";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['isLoading', 'sideBarMenuVisible']),
  },
  components: {LoadingOverlay, SidebarMenu},
}
</script>

<style>
.vue-container {
  position: relative;
  font-family: 'Poppins', sans-serif;
  background-image: url("assets/background.png");
  background-size: cover;
  min-height: 100vh;
}

.menu-container {
  z-index: 1;
}

.slide-burger-menu-leave-active, .slide-burger-menu-enter-active {
  transition: .8s;
  z-index: 1;
}

.slide-burger-menu-enter-from {
  transform: translate(-100%, 0);
}

.slide-burger-menu-leave-to {
  transform: translate(-100%, 0);
}
</style>
