import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: () => import("@/views/pages/LoginPage"),
    },
    {
        path: '/create-account',
        name: 'CreateAccount',
        component: () => import("@/views/pages/CreateAccount"),
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import("@/views/pages/ForgotPassword"),
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import("@/views/pages/Dashboard"),
    },
    {
        path: '/leave-status',
        name: 'LeaveStatus',
        component: () => import("@/views/pages/LeaveStatus"),
    },
    {
        path: '/employee-leave',
        name: 'EmployeeLeave',
        component: () => import("@/views/pages/EmployeeLeave"),
    },
    {
        path: '/profile',
        name: 'UserProfile',
        component: () => import("@/views/pages/UserProfile"),
    },
    {
        path: '/our-team',
        name: 'OurTeam',
        component: () => import("@/views/pages/OurTeam"),
    },
    {
        path: '/leave-calendar',
        name: 'LeaveCalendar',
        component: () => import("@/views/pages/LeaveCalendar"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

router.beforeEach(async (to, from, next) => {
    userAuthenticate(to, from, next);
})


export default router
