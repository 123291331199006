import store from "@/store";

export function userAuthenticate(to, from, next) {
    const allowedRoutes = ['CreateAccount', 'ForgotPassword'];

    if (allowedRoutes.includes(to.name)) {
        return next();
    }
    if (!store.state.userData && to.name !== 'LoginPage') {
        return next({name: 'LoginPage'});
    }
    next();
}
