<template>
  <div class="d-flex flex-column align-items-center sidebar-container">
    <img @click="closeClicked()" class="close-icon" src="../../assets/close-icon.png">
    <div class="d-flex flex-column align-items-center profile-container col-10">
      <img :src="profilePic" class="profile-pic-img mt-5"/>
      <div class="username-text mt-2"> {{ userData.Name + ' ' + userData.Surname }}</div>
      <div class="d-flex flex-column text-center menu-text mt-5">
        <div @click="routeToPage('dashboard')" class="mb-3">
          <div v-if="!isTextActive(this.$route, '/dashboard')">Home</div>
          <div class="active-text" v-if="isTextActive(this.$route, '/dashboard')">Home</div>
        </div>
        <div @click="routeToPage('leave-status')" class="mb-3">
          <div v-if="!isTextActive(this.$route, '/leave-status')">Leave Status</div>
          <div class="active-text" v-if="isTextActive(this.$route, '/leave-status')">Leave Status</div>
        </div>
        <div class="mb-3">
          <div @click="routeToPage('profile')" v-if="!isTextActive(this.$route, '/profile')">
            Profile
          </div>
          <div @click="routeToPage('profile')" v-if="isTextActive(this.$route, '/profile')" class="active-text">
            Profile
          </div>
        </div>
        <div v-if="userData.Admin === true" class="mb-3">
          <div @click="routeToPage('employee-leave')" v-if="!isTextActive(this.$route, '/employee-leave')">
            Employee Leave
          </div>
          <div @click="routeToPage('employee-leave')" v-if="isTextActive(this.$route, '/employee-leave')" class="active-text">
            Employee Leave
          </div>
        </div>
<!--        <div v-if="userData.Admin === true" class="mb-3">-->
<!--          <div @click="routeToPage('leave-calendar')" v-if="!isTextActive(this.$route, '/leave-calendar')">-->
<!--            Leave Calendar-->
<!--          </div>-->
<!--          <div @click="routeToPage('leave-calendar')" v-if="isTextActive(this.$route, '/leave-calendar')" class="active-text">-->
<!--            Leave Calendar-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <button @click="logoutClicked()" class="logout-button">Logout</button>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "SidebarMenu",
  data() {
    return {
      profilePic: undefined,
    }
  },
  computed: {
    ...mapState(['sideBarMenuVisible', 'userData']),
  },
  methods: {
    ...mapMutations(['setSidebarMenuVisible', 'setUserData']),
    closeClicked() {
      this.setSidebarMenuVisible(false);
    },
    setUserProfilePic() {
      const azureAccount = process.env.VUE_APP_AZURE_ACCOUNT;
      const containerName = process.env.VUE_APP_CONTAINER_NAME;
      const {Name, Surname} = this.userData;

      this.profilePic = `https://${azureAccount}.blob.core.windows.net/${containerName}/${Name}_${Surname}`;
    },
    isTextActive(route, textName) {
      if (route.path === textName) return true
    },
    routeToPage(pageRoute) {
      this.setSidebarMenuVisible(false);
      this.$router.push(`/${pageRoute}`)
    },
    logoutClicked() {
      this.routeToPage('login')
    },
  },
  beforeMount() {
    this.setUserProfilePic()
  },
}
</script>

<style scoped>
.sidebar-container {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 100vh;
  background-color: #efeded;
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 10px;
}

.profile-pic-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  border: 1px solid #EE5C36;
}

.profile-container {
  margin-top: 75px;
}

.username-text {
  font-weight: 800;
  font-size: 19px;
}

.menu-text {
  font-weight: 900;
  font-size: 18px;
}

.active-text {
  color: #EE5C36;
}

.logout-button {
  position: absolute;
  background-color: #EE5C36;
  color: #FFFFFF;
  font-size: 13px;
  bottom: 50px;
  border: 0;
  height: 36px;
  width: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

</style>
