import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "flatpickr/dist/flatpickr.css"
import "flatpickr/dist/themes/material_orange.css"

const app = createApp(App);

app.use(store).use(router).mount('#app')
